.markdown_content_container {
  padding: 4rem;
  min-height: calc(100% - 21rem);
  overflow: auto;
  background: rgba(0, 0, 0, 0.5);
}

.markdown_content_container h2 {
  color: #be1622;
  font-size: 40px;
}
.privacy_terms h5 {
  color: #fff;
}
.privacy_terms h3 {
  margin-top: 1rem;
}
.privacy_terms .markdown_content {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 2rem;
  padding: 4rem;
  min-height: calc(100% - 29rem);
}
.blur_bg {
  backdrop-filter: blur(4px);
}

.privacy_terms .markdown_content p {
  font-size: 2rem;
  font-weight: 300;
  color: #fff;
}

.privacy_terms .markdown_content h1 {
  font-size: 6rem !important;
  font-weight: 700 !important;
}
.privacy_terms .markdown_content h2 {
  font-size: 4rem !important;
  font-weight: 500 !important;
}
.rsc-header-title {
  font-size: initial !important;
}
.privacy_terms .markdown_content h3 {
  font-size: 2.4rem !important;
  font-weight: 400 !important;
}
.privacy_terms .markdown_content h4 {
  font-size: 2rem;
  font-weight: 400;
}
.privacy_terms .markdown_content h5 {
  font-size: 2rem !important;
  font-weight: 400 !important;
  color: rgba(255, 255, 255, 0.5);
}
.privacy_terms .markdown_content p,
.privacy_terms .markdown_content h5 p {
  font-size: 2rem;
  font-weight: 300;
  color: #fff;
}
.text-color {
  color: #fff;
}
.privacy_terms .markdown_content h6 {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
}

.privacy_terms .markdown_content li {
  font-size: 2rem;
  margin-bottom: 1rem;
  cursor: context-menu !important;
  color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 868px) {
  .underline {
    text-decoration: underline;
  }
  .markdown_content_container {
    padding: 2rem;
  }
  .privacy_terms .markdown_content {
    padding: 2rem;
  }
  .privacy_terms .markdown_content h1 {
    font-size: 5rem !important;
    font-weight: 700 !important;
  }
  .privacy_terms .markdown_content h2 {
    font-size: 2rem !important;
    font-weight: 500 !important;
  }
  .privacy_terms .markdown_content h3 {
    font-size: 1.8rem !important;
    font-weight: 400 !important;
  }
  .privacy_terms .markdown_content h4 {
    font-size: 1.4rem !important;
    font-weight: 400 !important;
  }
  .privacy_terms .markdown_content h5 {
    font-size: 1.4rem !important;
    font-weight: 400 !important;
  }
  .privacy_terms .markdown_content p,
  .wmde-markdow li,
  .privacy_terms .markdown_content p,
  .privacy_terms .markdown_content li {
    font-size: 1.4rem !important;
    font-weight: 300;
    font-family: "San Francisco", sans-serif !important;
  }
  .privacy_terms .markdown_content h6 {
    font-size: 1rem !important;
    font-weight: 300 !important;
  }
  #goes_up {
    bottom: 2.5rem;
    right: 12rem;
  }
}
