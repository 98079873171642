.setting_profile {
    line-height: 3.6rem;
}
.setting_profile_avatar {
    width: 11rem;
    height: 11rem;
    border-radius: 6rem;
    margin-right: 1rem;
    position: relative;
}
.setting_profile_avatar img {
    border-radius: 6rem;
}
.setting_profile_avatar .icon {
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 1rem;
}
.setting_profile_avatar img {
    width: 100%;
}
.settings .card__container {
    padding: 1.5rem;
    cursor: context-menu;
}
.settings .statistics {
    width: 15rem;
    flex-direction: column;
    justify-content: start;
    justify-content: space-between;
    padding: 1rem 0;
    height: 100%;
}
.settings__user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}
.settings__user .btn {
    width: 18rem;
}
.settings_username {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.settings_username h4:nth-child(1) {
    font-weight: bold;
    margin-right: 2rem;
}

.graph canvas {
    padding: 0 !important;
    margin-bottom: 0rem !important;
    max-height: 40vh !important;
}

.team_members {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.team_members_card {
    display: flex;
    flex-direction: column;
    width: 26rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.team_members_img {
    width: 15rem;
    height: 15rem;
    border-radius: 8rem;
    margin: 1rem 0;
}
