.input-group-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.input-group {
  display: flex;
  margin-bottom: 1rem;
  position: relative;
}
.input-group-container label {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}
.input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: var(--rgba-medium_7);
  box-shadow: inset 0px 0px 20px 3px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  border: 0;
  outline: 0;
  height: 3rem;
  padding: 0 1.2rem;
  flex: 1;
  color: var(--text_color);
}
.input-group-container textarea {
  padding-top: 0.5rem;
}
.input__icon .input {
  border-radius: 10px 0px 0px 1rem;
}
.input-group .nav h3 {
  font-size: 1.4rem;
}

.input-group-icon-container {
  display: flex;
}
.input-group-icon {
  flex: 1;
}
option {
  color: var(--text_color);
}

.input-icon {
  width: 3rem;
  height: 3rem;
  background: var(--dark);
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--text_color);
}

/* .input-file input {
    display: none;
}
.input-file button {
    margin: 0 !important;
} */

.input-file {
  display: flex;
  justify-content: flex-end;
}
.input-file h5:nth-child(2) {
  margin-left: 1rem;
}
.input-file .btn {
  margin-left: 1rem !important;
}
.filenames {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.input-file label {
  display: flex;
  margin: 0 !important;
  margin-right: 1rem;
  text-transform: capitalize;
  font-weight: 600 !important;
}

.event .event_label_side {
  width: 20rem;
  letter-spacing: 0;
  text-transform: capitalize;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
}
.checkbox {
  color: var(--tx);
  font-size: 1.5rem;
  font-weight: 500;
}

.checkbox svg {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  margin-right: 5px;
}
.checked svg {
  fill: var(--blue);
}
textarea {
  width: 95%;
  height: 7rem;
}

.toggle textarea,
.event textarea {
  height: 5rem;
  resize: vertical;
  min-height: 3rem;
}

/* .input-group h3 {
    color: var(--light);
    letter-spacing: 0.2em;
} */
.half-width {
  width: 50%;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.eventcal .eventcal-content-wrapper {
  background: red;
}

.select-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.options-container {
  width: 100%;
  position: absolute;
  right: 0rem;
  z-index: 1;
  top: 0;
  margin-top: 3rem;
}
.options-container h3 {
  font-weight: normal;
  letter-spacing: normal;
  color: var(--text_color);
}
.options {
  width: 100%;
  background: var(--medium);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: auto;
  max-height: 25vh;
}
.options .nav {
  height: 3rem;
}

.select-placeholder {
  opacity: 0.5;
}

.files {
  height: 73vh !important;
}
::placeholder {
  text-transform: capitalize;
  color: var(--text_color_5);
}
