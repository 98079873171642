.m_announcement_date {
  position: absolute;
  top: 1.3rem;
  right: 2rem;
  opacity: 0.8;
  color: var(--text_color_5);
}
.m_announcement_data {
  margin-bottom: 1rem;
  flex: 1;
}
.m_announcement_data .m_card__container {
  position: relative;
  padding: 1rem;
}
.m_announcement_data .m_card__container::before {
  content: "";
  position: absolute;
  border-bottom: 1px solid var(--light);
  bottom: 0;
  left: 0;
  width: 100%;
}
.m_announcement_title .m_overflow_text {
  flex: 0.3;
  text-align: center;
}

.normal .m_overflow_text {
  white-space: normal;
}

/* .m_announcement_container .m_table_header .m_flex-1:nth-child(1) {
    flex: 0.3;
}

.m_announcement_container .m_table_header .m_flex-1:nth-child(3) {
    flex: 0.2;
    text-align: center;
}

.m_teams_container .m_table_header .m_flex-1:last-child {
    text-align: center;
    flex: 0.1;
} */

.m_challenges .m_table_header .m_flex-1:nth-child(3) {
  flex: 0.2;
}
.m_challenges .m_table_header .m_flex-1:nth-child(4) {
  flex: 0.1;
}

.m_plans .m_table_header .m_flex-1:nth-child(4) {
  flex: 0.1;
}
