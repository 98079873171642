.m_home {
    font-family: "Roboto Mono", monospace;
}
.m_home_container {
    background-color: #000;
}
.m_home nav {
    display: flex;
    padding: 1.6rem 1rem;
    position: relative;
}
.m_login_register {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    z-index: 2;
}

.m_home_text {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
    text-align: center;
    font-family: "Roboto Mono", monospace;
    background-color: #000;
}

.m_login_register h4 {
    font-weight: bold;
    color: #fff;
}
.m_home_text_bottom {
    position: absolute;
    width: 100%;
    bottom: 1rem;
}

.m_glow {
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.7);
    font-weight: bold;
    color: #fff;
}

nav h3 {
    margin: 0 2rem;
}

.m_world_hack {
    font-size: 4rem;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5),
        0px 0px 40px rgba(255, 255, 255, 0.7);
}
.m_home_text h1 {
    margin-bottom: 30px;
}

/* Map */

.m_country,
.m_map {
    height: 70vh;
    position: absolute;
    top: 0;
    width: 100%;
}

.m_country svg,
.m_map svg {
    width: 100%;
    height: 100%;
    margin-top: 2rem;
}
.m_country svg path {
    fill: rgba(72, 72, 72, 0);
    stroke: rgba(72, 72, 72, 0);
    stroke-width: 1rem !important;
    transition: all 1s linear;
}
.m_map svg path {
    fill: rgb(72, 72, 72);
    stroke: rgb(72, 72, 72);
    stroke-width: 0.5rem !important;
}

.m_country .m_animate {
    stroke: var(--red) !important;
}
.m_country {
    z-index: 1 !important;
}

.m_animate {
    stroke-width: 2px !important;
    stroke: var(--red) !important;
}

.m_line-1 {
    margin: 3rem auto;
    position: relative;
    white-space: nowrap;
    font-size: 9.6rem;
    font-size: 4rem;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5),
        0px 0px 40px rgba(255, 255, 255, 0.7);
    color: #fff;
    font-weight: bold;
    margin-bottom: 3rem;
}

.m_typing_cursor {
    border-right: 0.3rem solid;
    animation: caret 1s steps(1) infinite;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5),
        0px 0px 40px rgba(255, 255, 255, 0.7);
}

@keyframes caret {
    50% {
        border-color: transparent;
        box-shadow: none;
    }
}
