.logs .table_header .flex-1:last-child {
    flex: 1.7;
}
.logs_search {
    flex: 1;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
}
.logs_search::before {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px solid var(--medium);
    bottom: 0;
    left: 0;
}

.logs .table_data_item {
    justify-content: flex-start !important;
    align-items: flex-start !important;
}
.logs .search {
    height: 3rem !important;
    flex: 0.4;
}
.logs .input {
    height: 3rem !important;
}
.logs .icon-search {
    height: 3rem !important;
}

.statistics_table {
    height: 70vh !important;
}

.hide_text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.event .input-group-icon {
    flex: 0.4;
}

.announcement_textarea textarea {
    height: 20rem;
}
.scoreboard .table_header .flex-1:nth-child(2) {
    flex: 4;
}
.event_responsive {
    display: flex;
    align-items: center;
}
.time_responsive {
    display: flex;
}
.color-picker {
    position: absolute;
    z-index: 2;
    top: -15rem;
    left: 100%;
    background-color: var(--medium);
    border-radius: 1rem;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    width: 26rem;
}
@media screen and (max-width: 1280px) {
    .event_responsive {
        flex-direction: column;
        align-items: flex-start;
    }
    .event .input-group-icon {
        flex: 0.5;
    }
}
@media screen and (max-width: 1080px) {
    .time_responsive {
        display: flex;
        flex-direction: column;
    }
    .margin_time {
        margin-top: 0.5rem;
    }
    .event .input-group-icon-container {
        flex-direction: column;
    }
    .event .input-group-icon-container .filenames {
        margin-left: 2rem;
    }
    .event .select-container {
        margin-left: 2rem;
    }
    .event .input {
        margin-left: 2rem;
    }
    .event .input-group-icon {
        flex: 1;
    }
    .color-picker {
        left: 35%;
    }
}
.event .event_responsive_file {
    flex: 1;
}
.statistics {
    width: 20vw;
    height: 25vh;
    background-color: var(--light);
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}
.settings .statistics {
    height: 11rem !important;
}
.statistics__item {
    text-align: center;
    color: #dddddd;
    font-style: normal;
}
.statistics__text {
    font-weight: bold;
    font-size: 10rem;
    line-height: 11.7rem;
    color: var(--text_color);
}
.statistics__label {
    font-weight: normal;
    font-size: 2.4rem;
    text-transform: capitalize;
    line-height: 2.8rem;
    color: var(--text_color);
}
.statistics-card {
    display: flex;
    flex-direction: column;
}
@media screen and (max-width: 768px) {
    .statistics__text {
        font-size: 7rem;
    }
}
.scoreboard-graph {
    margin: 2rem 0px;
    width: 98%;
}
.event {
    background: var(--medium);
    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1.5rem;
    position: relative;
}
.preview {
    height: 3rem;
    border-radius: 0.5rem;
    margin-bottom: 0.7rem;
    display: grid;
    place-items: center;
}

.width-50 {
    width: 30vw;
}
.width-70 {
    width: 40vw;
}
.image_viewer {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(255, 255, 255, 0.2);
    height: 25vh;
    width: 35rem;
    overflow: hidden;
    position: relative;
}
.image_viewer img {
    height: 100%;
}

.react-calendar {
    background: var(--light) !important;
    border-radius: 1rem;
    color: #dddddd;
    /* border: 0 !important; */
}

.react-calendar button {
    color: white;
}
.react-calendar button:hover {
    background: var(--accent);
}

.react-calendar button:enabled {
    background: var(--light);
}
.react-calendar button:enabled:hover {
    background: var(--accent);
}
.react-calendar__tile--active {
    background: var(--accent) !important;
}

.react-datetime-picker {
    color: var(--text_color) !important;
    /* background-color: var(--light) !important; */
    background: transparent !important;
    border: 0 !important;
    border-radius: 1rem !important;
    box-shadow: inset 0px 0px 20px 3px rgba(0, 0, 0, 0.25);
    margin-right: 1rem;
}

.react-datetime-picker .react-date-picker__wrapper::after {
    border-bottom: 0 !important;
    outline: 0 !important;
    border-radius: 1rem;
    padding: 0rem 1rem !important;
}

.react-datetime-picker__inputGroup__input {
    color: var(--text_color);
}

.react-datetime-picker__clock--open {
    background: var(--light) !important;
    border-radius: 1rem;
    border: 0 !important;
}

select {
    background-color: var(--medium) !important;
    border: 0;
    outline: 0;
}

select option {
    background-color: var(--medium) !important;
}

select option:checked,
select option:hover {
    background-color: var(--medium) !important;
    box-shadow: 0 0 10px 100px #000 inset;
}

.color-picker-container {
    display: flex;
    align-items: center;
}

.color-picker .input {
    width: 5rem;
    margin-right: 0.5rem;
}

.color-picker-flex {
    display: flex;
    align-items: center;
}
.react-colorful {
    width: 24rem !important;
}
.react-colorful__saturation {
    border-radius: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}
.react-colorful__hue {
    border-radius: 0.5rem !important;
    height: 1rem !important;
}
.react-colorful__last-control {
    margin-top: 0.5rem;
}
.react-colorful__pointer {
    width: 1.5rem !important;
    height: 1.5rem !important;
}
.color-display {
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    margin: 0 1rem;
    border: 1px solid var(--text_color_5);
}

.challenges_info {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
}
.challenges_info img {
    width: 100%;
}
.challenge_data_item {
    font-size: 1.6rem;
    line-height: 2.9rem;
    align-items: center;
    text-align: center;
    display: flex;
    color: var(--text_color);
    position: relative;
    min-height: 4rem;
    padding: 0 1rem;
}
.challenge_data_item::before {
    content: "";
    border-bottom: 1px solid var(--dark);
    background-color: var(--dark);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.challenges_info .table {
    flex: 1;
}
.team_challenges_img {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
}

.team_state_challenge {
    flex: 1;
    margin-left: 1rem;
}
.team_state_challenge_points {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.slider {
    display: flex;
    flex: 1;
    height: 1rem;
    background: var(--light);
    border-radius: 1rem;
}

.event_editor {
    position: relative;
}

.media_libray_markdown {
    position: absolute;
    z-index: 1;
    left: 27.5%;
    top: 1.7rem;
    color: gray;
}

.w-md-editor-toolbar svg {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    color: var(--text_color);
}
.w-md-editor-text textarea {
    height: 100%;
    border-radius: 1rem !important;
}

.media_libray_markdown svg {
    color: var(--text_color);
    font-size: 1.8rem;
}

.w-md-editor-toolbar {
    background-color: var(--dark) !important;
    border-bottom: 1px solid var(--medium) !important;
    border-radius: 1rem 1rem 0 0 !important;
}
.m_challenges .w-md-editor-toolbar,
.m_event .w-md-editor-toolbar {
    background-color: var(--medium) !important;
}
.w-md-editor-toolbar button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.w-md-editor-toolbar .active button {
    background-color: var(--light) !important;
}
.w-md-editor-toolbar button:hover {
    background-color: var(--medium) !important;
}
.w-md-editor-toolbar button:focus {
    background-color: var(--medium) !important;
}

.w-md-editor {
    background-color: var(--dark) !important;
    border-radius: 1rem !important;
}
.m_event .w-md-editor {
    background-color: var(--medium) !important;
}
.event .w-md-editor {
    min-height: 75vh;
}
.w-md-editor-text {
    background-color: var(--dark) !important;
}
.m_container .w-md-editor-text {
    background-color: var(--medium) !important;
}
.w-md-editor-text textarea {
    color: var(--text_color) !important;
}
.w-md-editor-preview {
    background-color: var(--dark) !important;
    box-shadow: none !important;
    border-left: 2px solid var(--medium);
}
.w-md-editor-area,
.w-md-editor-input {
    background-color: var(--dark) !important;
    color: var(--text_color) !important;
    position: relative !important;
}
.w-md-editor-content {
    background-color: var(--dark) !important;
    color: var(--text_color) !important;
    border-radius: 1rem !important;
}

.m_container .w-md-editor-preview {
    background-color: var(--medium) !important;
    border-left: 2px solid var(--medium);
}
.m_container.w-md-editor-area,
.m_container .w-md-editor-input {
    background-color: var(--medium) !important;
}
.m_container .w-md-editor-content {
    background-color: var(--medium) !important;
}

.event .w-md-editor-content {
    height: 90% !important;
}

.language-markdown {
    color: var(--text_color) !important;
}

.w-md-editor-bar svg {
    color: var(--text_color) !important;
}
