.m_table {
  position: relative;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.m_table__heading {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.m_table .m_table__heading {
  height: 4rem;
}
.m_table__heading .m_input-group {
  flex: 1;
}
.m_table_header {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 1rem;
  background-color: var(--light);
}
.m_table_header h3 {
  font-weight: bold;
  font-size: 1.4rem;
}
.m_table_data_item {
  width: 100%;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  min-height: 4rem;
  color: var(--text_color);
  position: relative;
  padding: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.m_table_data_item > * {
  overflow: hidden;
  text-overflow: ellipsis;
}
.m_table_data_item::before {
  content: "";
  position: absolute;
  width: 100%;
  border-bottom: 1px solid var(--medium);
  bottom: 0;
  left: 0;
}

.m_table_data {
  flex: 1;
  overflow: auto;
}
.m_pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.m_event_homepage_container {
  padding: 1rem;
  padding-bottom: 18rem;
  overflow: auto;
  position: relative;
}
.m_white_space {
  white-space: nowrap;
}
.m_white-space {
  white-space: normal;
}

.m_users_container,
.m_teams_container,
.m_announcement_container,
.m_scoreboard,
.m_challenges {
  flex: 1;
  display: flex;
}
.m_users_container .m_table,
.m_teams_container .m_table,
.m_announcement_container .m_table,
.m_scoreboard .m_table,
.m_challenges .m_table,
.m_plans .m_table {
  width: 100vw;
}

.m_challenges .m_table_data {
  width: 100vw;
}
.m_scoreboard {
  flex-direction: column;
  width: 100%;
}
.m_scoreboard .m_table {
  flex-direction: column;
  width: 100%;
  overflow: visible;
}

.m_announcement_container .m_toggle__card {
  top: 10%;
  left: 0;
  transform: none;
}
