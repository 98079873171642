.m_section {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    z-index: 10;
}

.m_section .loading {
    font: 20px Courier;
    color: #06bfff;
    text-shadow: 0 0 10px #06bfff, 0 0 20px #06bfff;
}

.m_section .loader {
    top: 50%;
    left: 50%;
    position: absolute;
    width: 160px;
    height: 160px;
    transform: translate(-50%, -50%);
}

.m_section .loader span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(calc(18deg * var(--i)));
    animation: animate 2s linear infinite;
}

.m_section .loader span::before {
    content: "";
    position: absolute;
    top: calc((20 - var(--i)) * 0.5px);
    left: calc((20 - var(--i)) * 0.5px);
    width: var(--i);
    height: var(--i);
    border-radius: 50%;
    background: #06bfff;
    box-shadow: 0 0 10px #06bfff, 0 0 20px #06bfff, 0 0 40px #06bfff,
        0 0 60px #06bfff;
}

@keyframes animate {
    0% {
        transform: rotate(calc(18deg * var(--i)));
    }
    100% {
        transform: rotate(calc(18deg * (var(--i) + 20)));
    }
}
