.m_plan_challenges {
  max-height: 60vh;
  min-height: 100px;
  padding: 0 10px;
  overflow: auto;
}

.m_plan_challenges_item {
  padding: 5px 20px;
  background: var(--medium);
  margin: 10px 0;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
