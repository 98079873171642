.m_auth__container {
  /* display: flex;
  justify-content: center;
  height: 100%; */
  margin-top: 30px;
}
.m_auth {
  width: 42.5rem;
  display: flex;
  justify-content: center;
  background: var(--medium);
  box-shadow: 0px 0px 50px 5px rgba(0, 0, 0, 0.25);
  border-radius: 2rem;
}
.m_auth__inputs {
  width: 100%;
  color: var(--text_color);
  padding: 0 2rem;
}
.m_login_logo {
  width: 100%;
  object-fit: contain;
  padding: 2rem;
}
.m_auth_login {
  text-align: center;
}
.m_auth_login .m_navbar_logo {
  display: flex;
  justify-content: center;
}

.m_pentest-auth-container .m_input-group svg {
  margin-top: 5px;
}
.m_pentest-auth-container .m_input-group-container label h6 {
  font-size: 14px !important;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
.m_pentest-auth-container .m_input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: rgb(255 255 255 / 14%);
  background-clip: padding-box;
  border: 1px solid rgb(255 255 255 / 35%);
  border-radius: 0.25rem;
  height: 40px;
}

.m_pentest-auth-container
  input[placeholder="confirm password"]
  + .m_input-icon {
  border-radius: 0 0.25rem 0.25em 0;
  border-left-color: transparent;
}
.m_pentest-auth-container .m_input-icon {
  height: 40px;
  border-radius: 0.25rem;
  background-color: rgb(255 255 255 / 14%);
  border: 1px solid rgb(255 255 255 / 35%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.m_pentest-auth-container .m_btn {
  border: 1px solid rgb(255 255 255 / 35%);
  width: 100%;
  height: 40px;
  border-radius: 0.25rem;
}
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid var(--light);
}

.m_login-separater {
  text-align: center;
  position: relative;
}

.m_login-separater span {
  position: relative;
  top: 26px;
  margin-top: -10px;
  background: var(--light);
  padding: 5px;
  font-size: 12px;
  color: var(--text_color);
  border-radius: 3px;
}
