.m_log_row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.m_log_tab svg {
    font-size: 1.8rem;
    margin-right: 0.3rem;
}
.m_log_row .m_spaced {
    letter-spacing: 0;
}
.m_log_row .m_flex-1:last-child {
    text-align: right;
}

.m_logs {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.m_logs .m_table_data_item {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    line-height: 2.5rem;
}

.m_log_tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.m_hide_text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.m_announcement_textarea textarea {
    height: 20rem;
}
.m_scoreboard .m_table_header .m_flex-1:nth-child(2) {
    flex: 4;
}

.m_color-picker {
    z-index: 2;
    background-color: var(--medium);
    border-radius: 1rem;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    width: 26rem;
}
.m_color-picker h4 {
    margin-top: 0.6rem;
}
.m_color-picker .m_input {
    width: 5rem;
    margin-left: 0.5rem;
    background: var(--rgba-medium_7);
    box-shadow: inset 0px 0px 20px 3px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
}

.m_event .m_event_responsive_file {
    flex: 1;
}
.m_event .m_nav h6 {
    font-size: 1.2rem;
}
.m_statistics {
    background-color: var(--light);
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}
.m_settings .m_statistics {
    height: 11rem !important;
}
.m_statistics__item {
    text-align: center;
    color: #dddddd;
    font-style: normal;
}
.m_statistics__text {
    font-weight: bold;
    font-size: 10rem;
    line-height: 11.7rem;
    color: var(--text_color);
}
.m_challenges .m_nav .m_icon {
    margin-right: 0.5rem;
}
.m_statistics__label {
    font-weight: normal;
    font-size: 1.8rem;
    text-transform: capitalize;
    color: var(--text_color);
}
.m_statistics-card {
    display: flex;
    flex-direction: column;
    margin: 3rem;
    width: 21rem;
    height: 15rem;
}
@media screen and (max-width: 768px) {
    .m_statistics__text {
        font-size: 7rem;
    }
}

.m_event {
    padding: 1.5rem;
    position: relative;
    flex: 1;
    width: 100vw;
}
.m_preview {
    height: 3rem;
    border-radius: 0.5rem;
    margin-bottom: 0.7rem;
    display: grid;
    place-items: center;
}

.m_width-50 {
    width: 30vw;
}
.m_width-70 {
    width: 40vw;
}
.m_image_viewer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25vh;
    overflow: hidden;
    position: relative;
}
.m_image_viewer img {
    height: 100%;
}

.react-calendar {
    background: var(--light) !important;
    border-radius: 1rem;
    color: #dddddd;
    /* border: 0 !important; */
}

.react-calendar button {
    color: white;
}
.react-calendar button:hover {
    background: var(--accent);
}

.react-calendar button:enabled {
    background: var(--light);
}
.react-calendar button:enabled:hover {
    background: var(--accent);
}
.react-calendar__tile--active {
    background: var(--accent) !important;
}

.react-datetime-picker {
    color: var(--text_color) !important;
    /* background-color: var(--light) !important; */
    background: transparent !important;
    border: 0 !important;
    border-radius: 1rem !important;
    box-shadow: inset 0px 0px 20px 3px rgba(0, 0, 0, 0.25);
    margin-right: 1rem;
}

.react-datetime-picker .react-date-picker__wrapper::after {
    border-bottom: 0 !important;
    outline: 0 !important;
    border-radius: 1rem;
    padding: 0rem 1rem !important;
}

.react-datetime-picker__inputGroup__input {
    color: var(--text_color);
}

.react-datetime-picker__clock--open {
    background: var(--light) !important;
    border-radius: 1rem;
    border: 0 !important;
}

select {
    background-color: var(--medium) !important;
    border: 0;
    outline: 0;
}

select option {
    background-color: var(--medium) !important;
}

select option:checked,
select option:hover {
    background-color: var(--medium) !important;
    box-shadow: 0 0 10px 100px #000 inset;
}

.m_color-picker-flex {
    display: flex;
    align-items: center;
}
.react-colorful {
    width: 24rem !important;
}
.react-colorful__saturation {
    border-radius: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}
.react-colorful__hue {
    border-radius: 0.5rem !important;
    height: 1rem !important;
}
.react-colorful__last-control {
    margin-top: 0.5rem;
}
.react-colorful__pointer {
    width: 1.5rem !important;
    height: 1.5rem !important;
}
.m_color-display {
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    margin-left: 0.8rem;
    border: 1px solid var(--text_color_5);
}

.m_challenges_info {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
}
.m_challenges_info img {
    width: 100%;
}
.m_challenge_data_item {
    font-size: 1.2rem;
    line-height: 3rem;
    display: flex;
    align-items: center;
    color: var(--text_color);
    position: relative;
}
.m_challenge_data_item::before {
    content: "";
    border-bottom: 1px solid var(--light);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.m_challenges_info .m_table {
    flex: 1;
}
.m_team_challenges_img {
    width: 4rem;
    height: 4rem;
}

.m_slider {
    display: flex;
    flex: 1;
    height: 1rem;
    background: var(--light);
    border-radius: 1rem;
}

.m_event_editor {
    position: relative;
}

.media_libray_markdown {
    position: absolute;
    z-index: 1;
    left: 27.5%;
    top: 1.7rem;
    color: gray;
}
.m_event .m_w-md-editor {
    min-height: 75vh;
}
