.m_navbar {
  display: flex;
  align-items: center;
  background: var(--medium);
  box-shadow: 0px 10px 30px 5px rgba(0, 0, 0, 0.25);
  height: 4.4rem;
  padding: 0 1.8rem;
  width: 100%;
}
.m_navbar_logo {
  display: flex;
  align-items: center;
  flex: 1;
}
.m_navbar_logo .m_icon {
  margin-right: 1rem;
}
.m_nav_logo {
  height: 4rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.m_nav_logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.m_nav_util {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.m_main_heading {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.m_user_container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}
.m_avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  margin: 0 1rem;
}
.m_avatar img {
  width: 100%;
  border-radius: 2rem;
}
.m_user {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 1rem;
}
.m_username {
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  text-align: right;
  color: var(--text_color);
}

.m_dropdown {
  position: absolute;
  top: 4.5rem;
  right: 0.5rem;
  width: 18rem;
  background: var(--medium);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
}
.m_dropdown_container {
  position: absolute;
  width: 18rem;
}
.m_dropdownList {
  background: var(--medium);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  right: 0rem;
  overflow: hidden;
}
.m_user .m_icon {
  cursor: pointer;
}

.m_user img {
  cursor: pointer;
}
.m_dropdown-icon-up {
  margin: 0;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: var(--medium);
}

/* Navbar */
.m_search {
  width: 100%;
}
.m_search .m_input {
  background: var(--dark);
}

.m_navbar .m_input-group {
  margin: 0;
}
