.m_input-group-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.m_input-group {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
}
.m_input-group-container label {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}
.m_input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    background: var(--medium);
    border-radius: 1rem;
    border: 0;
    outline: 0;
    height: 3rem;
    padding: 0 1.2rem;
    flex: 1;
    color: var(--text_color);
    font-size: 1.2rem;
}
.m_input-group-container textarea {
    padding-top: 0.5rem;
}

.m_input-group .m_nav h3 {
    font-size: 1.4rem;
}
.m_input__icon svg {
    position: absolute;
    font-size: 1.6rem !important;
    right: 0.8rem;
    top: 0.7rem;
    color: var(--text_color);
}
.m_input-group-icon-container {
    display: flex;
}
.m_input-group-icon {
    flex: 1;
}
option {
    color: var(--text_color);
}

.m_input-icon {
    width: 3rem;
    height: 3rem;
    background: var(--dark);
    border-radius: 0px 10px 10px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--text_color);
}

.m_input-file {
    display: flex;
    justify-content: flex-end;
}
.m_input-file h5:nth-child(2) {
    margin-left: 1rem;
}
.m_input-file .m_btn {
    margin-left: 1rem !important;
}
.m_filenames {
    display: flex;
    flex: 1;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
}

.m_input-file label {
    display: flex;
    margin: 0 !important;
    margin-right: 1rem;
    text-transform: capitalize;
    font-weight: 600 !important;
}

.m_event .m_event_label_side {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}
.m_checkbox {
    color: var(--tx);
    font-size: 1.5rem;
    font-weight: 500;
}

.m_checkbox svg {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    margin-right: 5px;
}
.m_checked svg {
    fill: var(--blue);
}
textarea {
    width: 95%;
    height: 7rem;
}

.m_toggle textarea,
.m_event textarea {
    height: 5rem;
    resize: vertical;
    min-height: 3rem;
}

/* .m_input-group h3 {
    color: var(--light);
    letter-spacing: 0.2em;
} */
.m_half-width {
    width: 50%;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.m_eventcal .m_eventcal-content-wrapper {
    background: red;
}

.m_select-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.m_options-container {
    width: 100%;
    position: absolute;
    right: 0rem;
    z-index: 1;
    top: 0;
    margin-top: 3rem;
}
.m_options-container h3 {
    font-weight: normal;
    letter-spacing: normal;
    color: var(--text_color);
}
.m_options {
    width: 100%;
    background: var(--medium);
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: auto;
    max-height: 25vh;
}
.m_options .m_nav {
    height: 3rem;
}

.m_select-placeholder {
    opacity: 0.5;
}
.m_files {
    height: 73vh !important;
}
::placeholder {
    text-transform: capitalize;
    color: var(--text_color_5);
}
