.m_icon {
    display: flex;
    align-items: center;
}
.m_icon svg {
    font-size: 2.4rem;
    margin: 0;
    color: var(--text_color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.m_icon_light svg {
    font-size: 1.6rem;
    color: var(--text_color_5);
}
.m_icon_4 svg {
    font-size: 3rem;
    cursor: pointer;
}
.m_close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--text_color);
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
}
.m_close svg {
    font-size: 2.3rem;
}

.m_icon__primary,
.m_icon__sm {
    width: 5rem;
    height: 5rem;
    background: var(--light);
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    margin-left: 0;
    cursor: pointer;
}

.m_input-file .m_icon__sm {
    margin: 0;
    margin-left: 1rem;
}
.m_icon__sm {
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
}
.m_icon__sm svg {
    font-size: 1.8rem;
}
.m_icon__primary:hover {
    background: var(--accent);
}
.m_icon__primary svg {
    font-size: 2.5rem;
}
.m_disable svg {
    color: var(--text_color_5);
    cursor: context-menu;
}
.m_disable .m_icon__primary {
    color: var(--text_color_5);
    cursor: context-menu;
    background: none;
}
.m_disable .m_tab-btn {
    color: var(--text_color_5);
    cursor: context-menu;
}

.m_active {
    background: transparent;
    box-shadow: none;
}

.m_pagination svg {
    font-size: 3.5rem;
    fill: var(--text_color);
}
.m_icon-search {
    width: 4rem;
    height: 4rem;
    border-radius: 10px 0px 0px 10px;
    display: flex;
    align-items: center;
    color: var(--text_color);
    position: absolute;
}
.m_icon-search svg {
    font-size: 2.5rem;
    margin-left: 1rem;
}

.m_toggle .m_input-icon {
    height: 3rem;
}

/* Custom */

.m_mv-1 {
    margin: 1rem 0;
}
.m_m-0 {
    margin: 0;
}
.m_mt--0_5 {
    margin-top: -1px;
}
.m_mt-1 {
    margin-top: 1rem;
}
.m_mt-2 {
    margin-top: 2rem;
}
.m_mt-4 {
    margin-top: 4.25rem;
}
.m_ml-1 {
    margin-left: 1rem;
}
.m_ml-2 {
    margin-left: 2rem;
}
.m_mr-1 {
    margin-right: 1rem;
}
.m_mb-1 {
    margin-bottom: 1rem;
}
.m_mb-2 {
    margin-bottom: 2rem;
}
.m_mb-4 {
    margin-bottom: 4rem;
}
.m_text-left {
    text-align: left;
}
.m_text-right {
    text-align: right;
}

.m_flex-column {
    flex-direction: column;
}
.m_d-flex {
    display: flex;
}
.m_align-items-center {
    align-items: center;
}
.m_justify-content-center {
    justify-content: center;
}
.m_justify-content-end {
    justify-content: flex-end;
}
.m_align-items-end {
    align-items: flex-end;
}
.m_justify-content-between {
    justify-content: space-between;
}
.m_justify-content-evenly {
    justify-content: space-evenly;
}
.m_justify-space-around {
    justify-content: space-around;
}
.m_flex-1 {
    flex: 1;
}
.m_flex-2 {
    flex: 2;
}
.m_flex-3 {
    flex: 3;
}
.m_flex-4 {
    flex: 4;
}
.m_p-1 {
    padding: 1rem;
}
.m_p-2 {
    padding: 2rem;
    flex: 1;
    overflow: auto;
}
