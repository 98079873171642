.m_not-found_container {
    background: #303030;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    overflow: auto;
}

.m_not-found_container h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    color: #ddd;
}
.m_not-found_container h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #ddd;
}
.m_not-found_container h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 1rem;
    color: #ddd;
}
.m_not_found_img {
    height: calc(100% - 240px);
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.m_not_found_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.m_not-found_container .m_text {
    height: 240px;
    overflow: auto;
}
