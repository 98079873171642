:root {
    --green: #3d9f00;
    --blue: #2f89db;
    --red: #be1622;
    --yellow: #ff9900;
}
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.m_notification {
    position: absolute;
    top: 0.6rem;
    right: 3rem;
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 2rem;
    background-color: var(--red);
    display: flex;
    align-items: center;
    justify-content: center;
}
.m_container {
    overflow: auto;
    position: relative;
    display: flex;
    background-color: var(--dark);
}
.m_event_homepage {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: var(--dark);
}
.m_event_footer {
    background-color: var(--medium);
    left: 0;
    padding: 2rem;
    position: absolute;
    width: 100%;
    bottom: 0;
}

.m_toggle {
    position: fixed;
    top: 0;
    left: 0;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
}
.MuiTooltip-popper {
    top: -22px !important;
}

.MuiTooltip-tooltip {
    background-color: var(--light) !important;
    font-size: 1rem !important;
}
.MuiTooltip-arrow::before {
    background-color: var(--light) !important;
}

.m_toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.m_toggle-container .m_toggle_card {
    position: relative;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    width: calc(100vw - 4rem);
    border-radius: 1rem;
    padding: 2rem;
}
.m_toggle__card {
    padding: 2rem;
    flex: 1;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.main_items {
    flex: 1;
    overflow: auto;
}
.main_items a:first-child .m_category_item {
    margin-top: 0.7rem;
}
.main_items a:last-child .m_category_item {
    margin-bottom: 0.7rem;
}
.datetimepicker-control-section {
    max-width: 246px;
    margin: 30px auto;
    padding-top: 50px;
}
.w-md-editor-toolbar-divider {
    background-color: var(--text_color_5);
    margin-top: -1rem !important;
}
.m_responsive_profile {
    display: flex;
    flex-direction: column;
}
/* .grecaptcha-badge {
    visibility: hidden !important;
} */

.DraftEditor-editorContainer {
    overflow: hidden;
}
.toolbarClassName {
    background-color: var(--dark) !important;
    border: 0;
    position: relative;
    padding: 1rem;
    border-radius: 1rem !important;
    margin-top: 1rem;
}
.public-DraftEditor-content {
    margin-left: 1rem;
}
.toolbarClassName img {
    filter: invert(1);
}
.rdw-dropdown-wrapper {
    outline: 0 !important;
    border: 0 !important;
}
.rdw-block-dropdown {
    outline: 0 !important;
    border: 0 !important;
}
.rdw-block-wrapper {
    outline: 0 !important;
    border: 0 !important;
}
.rdw-dropdown-selectedtext {
    background-color: var(--dark) !important;

    outline: 0 !important;
    border: 0 !important;
    color: var(--text_color);
}
.rdw-block-wrapper {
    background-color: var(--dark) !important;
}
.rdw-option-wrapper {
    border: 0;
    outline: 0;
    background-color: var(--dark);
}
.rdw-editor-wrapper {
    border-radius: 1rem !important;
}
.wrapperClassName {
    background-color: var(--dark);
}
.editorClassName {
    color: var(--text_color);
    font-size: 2rem;
}
/* Animations */

@-webkit-keyframes rotateInDownLeft {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        opacity: 0;
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes rotateInDownLeft {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        opacity: 0;
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

.animate__rotateInDownLeft {
    -webkit-animation-name: rotateInDownLeft;
    animation-name: rotateInDownLeft;
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes rotateInDownRight {
    0% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes rotateInDownRight {
    0% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

.animate__rotateInDownRight {
    -webkit-animation-name: rotateInDownRight;
    animation-name: rotateInDownRight;
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/* ANIMATION DELAY FOR SLIDE FROM LEFT */

.animate__rotateInDownLeft.animate__delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-delay: var(--animate-delay);
    animation-delay: var(--animate-delay);
}

.animate__rotateInDownLeft.animate__delay-2s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-delay: calc(var(--animate-delay) * 2);
    animation-delay: calc(var(--animate-delay) * 2);
}

.animate__rotateInDownLeft.animate__delay-3s {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
    -webkit-animation-delay: calc(var(--animate-delay) * 3);
    animation-delay: calc(var(--animate-delay) * 3);
}

.animate__rotateInDownLeft.animate__delay-4s {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-delay: calc(var(--animate-delay) * 4);
    animation-delay: calc(var(--animate-delay) * 4);
}

/* ANIMATION DEAY FOR SLIDE FROM RIGHT */
.animate__rotateInDownRight.animate__delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-delay: var(--animate-delay);
    animation-delay: var(--animate-delay);
}

.animate__rotateInDownRight.animate__delay-2s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-delay: calc(var(--animate-delay) * 2);
    animation-delay: calc(var(--animate-delay) * 2);
}

.animate__rotateInDownRight.animate__delay-3s {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
    -webkit-animation-delay: calc(var(--animate-delay) * 3);
    animation-delay: calc(var(--animate-delay) * 3);
}

.animate__rotateInDownRight.animate__delay-4s {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-delay: calc(var(--animate-delay) * 4);
    animation-delay: calc(var(--animate-delay) * 4);
}
/* ZOOM IN UP ANIMATION */
@-webkit-keyframes zoomInUp {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(
            0.55,
            0.055,
            0.675,
            0.19
        );
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

@keyframes zoomInUp {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(
            0.55,
            0.055,
            0.675,
            0.19
        );
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

.animate__zoomInUp {
    -webkit-animation-name: zoomInUp;
    animation-name: zoomInUp;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/* ZOOM IN UP DELAY ANIMATION */
.animate__zoomInUp.animate__delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-delay: var(--animate-delay);
    animation-delay: var(--animate-delay);
}

.animate__zoomInUp.animate__delay-2s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-delay: calc(var(--animate-delay) * 2);
    animation-delay: calc(var(--animate-delay) * 2);
}

.animate__zoomInUp.animate__delay-3s {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
    -webkit-animation-delay: calc(var(--animate-delay) * 3);
    animation-delay: calc(var(--animate-delay) * 3);
}

.animate__zoomInUp.animate__delay-4s {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-delay: calc(var(--animate-delay) * 4);
    animation-delay: calc(var(--animate-delay) * 4);
}

/* FADE IN LEFT BIG ANIMATION */
@-webkit-keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-1000px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.animate__fadeInLeftBig {
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/* FDAE IN UP ANIMATION */
@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.animate__fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-animation-duration: 700ms;
    animation-duration: 700ms;
    -webkit-animation-duration: 700ms;
    animation-duration: 700ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.pointer {
    cursor: pointer;
}

.MuiToolbar-gutters {
    background-color: var(--medium) !important;
    font-family: "Roboto Mono", monospace !important;
}
.MuiPickersDay-daySelected {
    background-color: green;
}
.MuiPickersBasePicker-pickerView {
    background-color: var(--medium);
}

.MuiPickersToolbarText-toolbarBtnSelected {
    font-family: "Roboto", sans-serif !important;
}
.MuiTypography-root {
    font-family: "Roboto", sans-serif !important;
    color: var(--text_color) !important;
}
.MuiPickersToolbarText-toolbarTxt {
    font-size: 2rem !important;
}
.MuiPickersToolbarText-toolbarBtnSelected {
    font-size: 3.75rem !important;
    font-weight: bold !important;
}
.MuiPickersCalendarHeader-switchHeader {
    display: flex !important;
    align-items: center !important;
}
.MuiPickersTimePickerToolbar-ampmLabel {
    font-size: 2rem !important;
}
.MuiTypography-colorInherit {
    font-size: 1.5rem !important;
}
.MuiTypography-caption {
    font-size: 1.5rem !important;
    color: rgba(255, 255, 255, 0.4) !important;
}
.MuiTypography-body1 {
    margin-top: -0.3rem !important;
    font-size: 1.8rem !important;
}
.MuiTypography-h2 {
    font-size: 5rem !important;
}
/* MuiButtonBase-root MuiIconButton-root  */
.MuiPickersCalendarHeader-iconButton {
    background-color: var(--medium) !important;
}
.MuiPickersCalendarHeader-iconButton svg {
    background-color: var(--medium);
    color: var(--text_color);
    font-size: 3rem;
}
.MuiPickersDay-daySelected {
    border-radius: 5px !important;
    background-color: var(--light) !important;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15) !important;
}

.MuiButton-textPrimary {
    background-color: var(--light) !important;
    color: var(--text_color) !important;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15) !important;
    border-radius: 1rem !important;
    border: 0 !important;
    outline: 0 !important;
    cursor: pointer !important;
    font-style: normal !important;
    text-transform: capitalize !important;
    font-size: 1.4rem !important;
    color: var(--text_color) !important;
    padding: 0.3rem 1em !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 10rem !important;
}
.MuiButton-textPrimary:hover {
    background-color: var(--accent) !important;
}

.MuiDialogActions-spacing {
    background-color: var(--medium) !important;
}

.MuiPickersYear-root {
    font-size: 1.5rem !important;
    font-weight: normal !important;
}

.MuiPickersYear-yearSelected {
    background-color: var(--light) !important;
    font-size: 1.5rem !important;
}
.MuiPickersClockPointer-pointer {
    background-color: var(--light) !important;
}
.MuiPickersClock-pin {
    background-color: var(--light) !important;
}
.MuiPickersClockPointer-thumb {
    border: 14px solid var(--light) !important;
}
.MuiPickersClock-pin {
    background-color: var(--light) !important;
}
.MuiPickersClockNumber-clockNumberSelected {
    background-color: var(--light) !important;
}
.MuiIconButton-sizeSmall .MuiSvgIcon-fontSizeSmall {
    font-size: 2rem !important;
}
.MuiAlert-message {
    font-size: 1.6rem !important;
}
.MuiPaper-rounded {
    border-radius: 1rem !important;
}
.MuiInput-underline .MuiIconButton-root {
    position: absolute !important;
}
.MuiInput-underline .MuiIconButton-root svg {
    color: var(--text_color);
    font-size: 2rem;
    background: var(--light) !important;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 4rem;
    position: absolute;
}
.MuiInput-underline {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
    background: var(--rgba-medium_7) !important;
    box-shadow: inset 0px 0px 20px 3px rgba(0, 0, 0, 0.25) !important;
    border-radius: 0.8rem !important;
    border: 0 !important;
    outline: 0 !important;
    height: 3rem !important;
    padding: 0 1rem !important;
    flex: 1 !important;
    color: var(--text_color) !important;
    margin-right: 5rem;
    font-size: 1.3rem !important;
    width: 12rem !important;
}
.spin {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes spin {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}
.from {
    margin-right: 1rem;
}
.MuiInput-underline::before {
    border: 0 !important;
}
.MuiInput-underline::after {
    border: 0 !important;
}

.m_event .MuiInput-formControl {
    background-color: var(--medium) !important;
    box-shadow: none !important;
    width: calc(90% - 4rem) !important;
}
