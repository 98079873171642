.challenge_page .btn {
  width: 10rem;
  height: 3rem;
  padding: 0;
}
.challenge_detail_page_container {
  width: 70vw;
}

.card__container .challenge_sm_loading {
  position: absolute;
  top: 3rem;
  right: 3.5rem;
}
.challenge_sm_loading .MuiCircularProgress-indeterminate {
  width: 2.5rem !important;
  height: 2.5rem !important;
}
.challenge_sm_loading svg {
  font-size: 1rem !important;
}
.locked_challenge {
  position: absolute;
  top: 1.8em;
  right: 2rem;
  padding: 0.5rem;
  background: var(--light);
  border-radius: 5px;
}
.locked_challenge svg {
  font-size: 2rem !important;
}
.challenge_response_view {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
}
.challenge_response_view .input {
  margin: 0;
  flex: 1 0 20%;
}
.challenge_response_view .input-group-container {
  margin: 0;
  padding: 0;
  margin: 0.5rem;
  flex: 1 0 20%;
}
.challenge_page {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
}

.tick {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}

.challenges_container_profile .table {
  display: flex;
}

.challenge_page .card__container {
  margin: 1rem;
  flex: 1 0 calc((100% - 1rem) / 2);
  max-width: calc((100% - 4rem) / 2);
  height: 12rem;
  cursor: pointer;
}
.profile_challenge_card {
  margin: 1rem;
  display: flex;
  flex: 1 0 calc((100% - 1rem) / 2);
  max-width: calc((100% - 4rem) / 2);
  background-color: var(--button_light);
  padding: 0.5rem 1rem;
  height: 6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.profile_challenge_card svg {
  font-size: 2rem;
}

.all_icons {
  height: 20vh;
  overflow: auto;
  z-index: 2;
  background: var(--light);
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 30;
}

.category_big svg {
  font-size: 6rem;
}

@media screen and (max-width: 1080px) {
  .challenge_page .card__container {
    flex: 1 0 96%;
    max-width: 96%;
  }
}
@media screen and (max-width: 1080px) {
  .profile_challenge_card {
    flex: 1 0 96%;
    max-width: 96%;
  }
}

.challenge_page .card__primary {
  padding: 2rem;
  position: relative;
}
.active_row {
  border: 1px solid var(--text_color_5);
  border-radius: 1px;
}
.challenge_category_type {
  position: relative;
  display: flex;
  margin: 2rem 0;
  align-items: center;
}
.challenge_category_type .btn {
  height: 3rem;
  cursor: context-menu;
}
.challenge_category_type .btn {
  margin-right: 3rem;
}
.enpoint_challenges {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}
.challengePanel .toggle__card {
  width: 60vw;
}
.categories_panel .toggle__card {
  width: 40vw;
}
.icon_categories .icon {
  margin-right: 1rem;
}
.icon_categories .btn {
  width: 11.5rem;
}
.info {
  display: flex;
}
.info .table__heading {
  height: 2rem;
  margin-top: 1rem;
}
.info .table__heading h5 {
  font-size: 1rem;
}

.tabs {
  display: flex;
}
.tabs .btn {
  box-shadow: none;
  border: 1px solid var(--light);
  border-radius: 1rem 1rem 0 0;
}
.tabs .active {
  background: transparent;
  border-radius: 1rem 1rem 0 0;
}

.challenge_detail_page {
  border-radius: 0rem 1rem 1rem 1rem;
  border: 1px solid var(--light);
  padding: 1rem;
  height: 76vh;
  overflow: auto;
}
