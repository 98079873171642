.m_sidebar-container {
    background: var(--medium);
    box-shadow: 10px 0px 30px 3px rgba(0, 0, 0, 0.25);
    position: relative;
    display: flex;
    height: 5rem;
}
.m_sidebar-container a {
    display: block;
    flex: 1;
}

.m_hide {
    display: none;
    opacity: 0;
}
.m_show {
    display: block;
    opacity: 1;
}
.m_nav {
    display: flex;
    padding: 1rem;
    align-items: center;
    white-space: nowrap;
    position: relative;
    background-position: center;
    transition: background 1s;
    overflow: hidden;
    text-overflow: ellipsis;
}
.m_nav::before {
    content: "";
    position: absolute;
    border-bottom: 1px solid var(--dark);
    bottom: 0;
    left: 0;
    width: 100%;
}
.m_sidebar-container .m_nav {
    flex-direction: column;
    border: none !important;
    background: var(--medium);
}

.m_sidebar-container .m_nav::before {
    content: "";
    border: none !important;
}
.m_input-group-container .m_nav:before {
    width: 100%;
}
.m_nav_heading_sidebar::after {
    content: "";
    position: absolute;
    border-bottom: 1px solid var(--dark);
    background-color: var(--dark);
    width: 100%;
    top: 0;
    left: 0;
}

.m_icon_heading svg {
    opacity: 0.3;
}
.m_nav svg {
    font-size: 2.2rem;
}
.m_nav h3,
.m_nav h5 {
    margin-left: 0.6rem;
}
.m_nav:hover svg {
    color: var(--text_color);
}
.m_nav:hover h3 {
    color: var(--text_color);
}

.m_count {
    opacity: 0.5;
}

.m_game_clock {
    padding: 1rem;
    text-align: center;
    font-family: "Roboto Mono", monospace;
    margin-top: 0.5rem;
    position: absolute;
    bottom: 1rem;
    margin-left: 1rem;
}

.m_time_clock h2 {
    font-size: 3.6rem;
}
.m_game_clock h2 {
    font-size: 3rem;
    font-weight: bold;
}
.m_game_clock h5 {
    font-weight: bold;
}

.m_overflow_text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}

.m_tooltip_no span {
    display: none;
    z-index: 2;
    background-color: var(--dark);
    border-radius: 0.5rem;
    padding: 1em;
    /* visibility: visible; */
}
.m_tooltip_no:hover span {
    display: block;
    position: fixed;
    overflow: hidden;
}
