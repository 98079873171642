.table {
    background: var(--medium);
    box-shadow: 0px 0px 50px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1rem 1.5rem;
    position: relative;
    margin-bottom: 1.5rem;
    overflow-x: auto;
}
.logs .table {
    min-width: 90rem;
}
.challenges .table {
    min-width: 90rem;
}
.table__heading {
    display: flex;
    align-items: center;
    text-transform: uppercase;
}
.table .table__heading {
    height: 4rem;
}
.table__heading .input-group {
    flex: 1;
}
.table_header {
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    height: 4rem;
    padding-left: 1.5rem;
    background-color: var(--light);
}

.table_header h3 {
    font-weight: bold;
    line-height: 2.1rem;
}
.table_data_item {
    font-size: 1.6rem;
    line-height: 2.9rem;
    display: flex;
    align-items: center;
    min-height: 4rem;
    color: var(--text_color);
    position: relative;
    padding-left: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table_data_item > * {
    overflow: hidden;
    text-overflow: ellipsis;
}
.table_data_item::before {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px solid var(--dark);
    bottom: 0;
    left: 0;
}
.table_data_item:nth-child(even) {
    background-color: var(--button_light);
}

.table_data {
    padding-bottom: 1rem;
    overflow: auto;
}
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.event_homepage .table {
    padding: 2rem;
}

.white_space {
    white-space: nowrap;
}
.white-space {
    white-space: normal;
}
