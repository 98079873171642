.plans_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
}
.plans_card_container {
  background: var(--light);
  padding: 20px;
  border-radius: 10px;
  min-width: 80%;
}

.plan_card {
  background: var(--medium);
  border-radius: 15px;
  line-height: 1.5;
  min-width: 300px;
  flex: 0 1 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.plan_description {
  position: relative;
  width: 100%;
  padding: 10px 0;
  overflow: auto;
  flex: 1;
}

.plan_card img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px 0;
  border-radius: 10px;
}

.plan_header {
  background: var(--dark);
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.plan_details {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.plan_details:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  border-top: 1px solid var(--light);
}
