.home {
    height: 100vh;
    width: 100vw;
    background: radial-gradient(#222222, #000000);
    font-family: "Roboto Mono", monospace;
}

.home nav {
    display: flex;
    padding: 1.6rem 2rem;
    position: relative;
}

.home_text {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: "Roboto Mono", monospace;
    z-index: 2;
}

.home_text h4 {
    position: absolute;
    width: 100%;
    bottom: 10px;
    text-align: center;
}
nav .login_register {
    position: absolute;
    right: 0;
    display: flex;
    cursor: pointer;
    z-index: 3;
}
nav .login_register h4 {
    color: #fff;
    font-weight: bold;
    font-size: 1.4rem;
}
.home_text_bottom {
    position: absolute;
    top: 60%;
    width: 100%;
    height: 40%;
}
nav h4 {
    font-weight: bold;
    font-size: 1.4rem;
}

.glow {
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.7);
    font-weight: bold;
    color: #fff;
}
.powered {
    position: absolute;
    width: 100%;
    bottom: 1rem;
}

.country,
.map {
    height: 90vh;
    position: absolute;
    top: 2rem;
    width: 100%;
}

.country svg,
.map svg {
    width: 100%;
    height: 100%;
}
.country svg path {
    fill: rgba(72, 72, 72, 0);
    stroke: rgba(72, 72, 72, 0);
    stroke-width: 1rem !important;
    transition: all 1s linear;
}
.map svg path {
    fill: rgb(72, 72, 72);
    stroke: rgb(72, 72, 72);
    stroke-width: 0.5rem !important;
}

.country .animate {
    stroke: var(--red) !important;
}
.country {
    z-index: 1 !important;
}
.world_hack {
    font-size: 9.6rem;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5),
        0px 0px 40px rgba(255, 255, 255, 0.7);
}
.home_text h1 {
    margin-bottom: 30px;
}

.line-1 {
    margin: 0 auto;
    position: relative;
    white-space: nowrap;
    font-size: 9.6rem;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5),
        0px 0px 40px rgba(255, 255, 255, 0.7);
    color: #fff;
    font-weight: bold;
    margin-bottom: 3rem;
}

.typing_cursor {
    border-right: 0.3rem solid;
    animation: caret 1s steps(1) infinite;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5),
        0px 0px 40px rgba(255, 255, 255, 0.7);
}

@keyframes caret {
    50% {
        border-color: transparent;
        box-shadow: none;
    }
}
