.m_create-join_team {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.m_create-join_team button {
    margin: 4rem 0;
}

.m_card__container {
    overflow: hidden;
}

.m_challenge__img {
    width: 6rem;
    height: 6rem;
    border-radius: 6rem;
}
.m_card__primary {
    color: var(--text_color);
    position: relative;
}
.m_card__secondary {
    padding: 0 1rem;
    background-color: var(--medium);
}

.m_card_img {
    flex: 0.15;
}
.m_card__heading {
    font-size: 1.6rem;
    overflow: hidden;
}

.m_card_p {
    word-wrap: break-word;
    word-break: break-all;
    font-style: normal;
    font-size: 1.4rem;
    display: flex;
    position: relative;
    align-items: center;
}
.m_card_p_lg {
    font-size: 1.4rem;
}
