.not-found_container {
    height: 100vh;
    width: 100vw;
    /* background: radial-gradient(#222222, #000000); */
    background: #303030;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    display: flex;
}
.not-found_container .flex-1 {
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;
    justify-content: center;
}
.not-found_container img {
    width: 100%;
}
.not-found_container h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 144px;
    line-height: 169px;
    color: #ddd;
}
.not-found_container h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #ddd;
}
.not-found_container h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-top: 1rem;
    color: #ddd;
}

.flex-1 .text {
    padding: 10rem;
}

.flex-1 .text .btn {
    margin-top: 2rem;
    width: 15rem;
}
