body {
    font-family: "Roboto", sans-serif;
}
/* #root {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0;
    height: -webkit-fill-available;
} */
html {
    font-size: 62.5%;
}
*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Scroll */

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #000;
    border-radius: 5px;
    background: var(--medium);
}

::-webkit-scrollbar-thumb {
    background: var(--light);
    border-radius: 5px;
}

/* ::-webkit-scrollbar-thumb:hover {
    background: var(--text_color_5);
} */
