.announcements_card {
  padding: 3rem 2rem;
  position: relative;
  margin-bottom: 2rem;
}

.announcement_date {
  position: absolute;
  top: 3.2rem;
  right: 2rem;
  opacity: 0.8;
}
.announcement_data {
  margin-bottom: 1rem;
}
.announcement_title .overflow_text {
  flex: 0.3;
  text-align: center;
}

.normal .overflow_text {
  white-space: normal;
}

.announcement_container .table_header .flex-1:nth-child(1) {
  flex: 0.3;
}

.announcement_container .table_header .flex-1:nth-child(3) {
  flex: 0.2;
  text-align: center;
}

.users_container .table_header .flex-1:last-child {
  text-align: center;
}
.teams_container .table_header .flex-1:nth-child(2) {
  text-align: center;
  flex: 1;
}

.challenges .table_header .flex-1:nth-child(1) {
  flex: 0.5;
}
.challenges .table_header .flex-1:nth-child(2) {
  flex: 0.2;
}
.challenges .table_header .flex-1:nth-child(3) {
  flex: 1;
}
.challenges .table_header .flex-1:nth-child(4) {
  flex: 0.3;
}
.challenges .table_header .flex-1:nth-child(5) {
  flex: 0.3;
}
.challenges .table_header .flex-1:nth-child(6) {
  text-align: center;
  flex: 0.4;
}
.challenges .table_header .flex-1:nth-child(7) {
  text-align: center;
  flex: 0.3;
}

.plans .table_header .flex-1:nth-child(1) {
  flex: 0.5;
}
.plans .table_header .flex-1:nth-child(2) {
  flex: 1;
}
.plans .table_header .flex-1:nth-child(3) {
  flex: 0.3;
}
.plans .table_header .flex-1:nth-child(4) {
  flex: 0.3;
}
.plans .table_header .flex-1:nth-child(5) {
  flex: 0.3;
}
.plans .table_header .flex-1:nth-child(6) {
  text-align: center;
  flex: 0.3;
}
