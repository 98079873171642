.navbar {
  display: flex;
  align-items: center;
  background: var(--medium);
  box-shadow: 0px 10px 30px 5px rgba(0, 0, 0, 0.25);
  height: 6.4rem;
  padding: 0 1.8rem;
  width: 100%;
}
.navbar_logo {
  display: flex;
  align-items: center;
  flex: 1;
}
.navbar_logo .icon {
  margin-right: 1rem;
}
.nav_logo {
  height: 4rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.nav_logo img {
  height: 100%;
  width: 90%;
  object-fit: contain;
}

.nav_util {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.user_container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}
.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  margin: 0 1rem;
}
.avatar img {
  width: 100%;
  border-radius: 2rem;
}
.user {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 1rem;
}
.username {
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  text-align: right;
  color: var(--text_color);
}

.dropdown {
  position: absolute;
  top: 4.5rem;
  right: 0.5rem;
  width: 18rem;
  background: var(--medium);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
}
.dropdown_container {
  position: absolute;
  width: 18rem;
}
.dropdownList {
  background: var(--medium);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  right: 0rem;
  overflow: hidden;
}
.user .icon {
  cursor: pointer;
}

.user img {
  cursor: pointer;
}
.dropdown-icon-up {
  margin: 0;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: var(--medium);
}

/* Navbar */
.search .input {
  border-radius: 10px;
  margin: 0;
  flex: 1;
  height: 4rem;
  padding-left: 4rem;
}

.navbar .input-group {
  margin: 0;
}
.search {
  flex: 2;
  display: flex;
}
