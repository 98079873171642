.m_challenge_page {
    position: relative;
    flex: 1;
}

.m_challenge_page .m_btn {
    width: 9rem;
    height: 2.4rem;
    padding: 0;
}

.m_category_item {
    display: flex;
    align-items: center;
    padding: 0.7rem 2rem;
}
.m_category_item .m_icon {
    margin-right: 1.5rem;
}

.m_card__container .m_challenge_sm_loading {
    position: absolute;
    top: 2rem;
    right: 2.5rem;
}

.m_challenge_page .m_card__container {
    position: relative;
    height: 9rem;
}
.m_challenge_page .m_card__container::before {
    content: "";
    border-bottom: 1px solid var(--light);
    width: 100%;
    position: absolute;
    bottom: 0;
}
.m_challenge_sm_loading .MuiCircularProgress-indeterminate {
    width: 2.5rem !important;
    height: 2.5rem !important;
}
.m_challenge_sm_loading svg {
    font-size: 1rem !important;
}
.m_tick {
    position: absolute;
    bottom: 1.5rem;
    right: 1rem;
}

.m_challenges_container_profile .m_table {
    display: flex;
}

.m_profile_challenge_card {
    display: flex;
    flex: 1;
    font-size: 1.2rem;
    color: var(--text_color);
    margin-bottom: 1rem;
    position: relative;
    padding: 0.5rem 0;
}
.m_profile_challenge_card svg {
    font-size: 2rem;
}
.m_profile_challenge_card::before {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px solid var(--light);
    bottom: 0;
    left: 0;
}

.m_all_icons {
    height: 20vh;
    overflow: auto;
    z-index: 2;
    background: var(--light);
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 30;
}

.m_category_big svg {
    font-size: 6rem;
}

.m_challenge_page .m_card__primary {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
}
.m_active_row {
    border: 1px solid var(--text_color_5);
    border-radius: 1px;
}
.m_challenge_category_type {
    position: relative;
    display: flex;
    margin: 1rem 0;
    align-items: center;
}
.m_challenge_category_type .m_btn {
    height: 2.4rem;
    width: 9rem;
}

.enpoint_challenges {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
}
.m_challengePanel .m_toggle__card {
    width: 60vw;
}

.m_info {
    width: calc(100vw - 4rem);
}
.m_info .m_table__heading {
    height: 2rem;
    margin-top: 1rem;
}
.m_info .m_table__heading h5 {
    font-size: 1rem;
}
