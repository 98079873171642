.sidebar-container {
    background: var(--medium);
    box-shadow: 10px 0px 30px 3px rgba(0, 0, 0, 0.25);
    width: 6.4rem;
    position: relative;
}

.selected {
    background-color: var(--dark-selected);
}
.expanded {
    width: 24rem;
}
.hide {
    display: none;
    opacity: 0;
}
.show {
    display: block;
    opacity: 1;
}
.nav,
.nav_heading_sidebar {
    display: flex;
    padding: 0 2rem;
    align-items: center;
    height: 4rem;
    cursor: context-menu;
    white-space: nowrap;
    position: relative;
    flex: 1;
}
.nav {
    background-position: center;
    transition: background 1s;
}
.nav:hover {
    background: var(--accent)
        radial-gradient(circle, transparent 1%, var(--accent) 1%) center/15000%;
}
.nav:active {
    background-color: rgba(0, 0, 0, 0.1);
    background-size: 100%;
    transition: background 0s;
}
.nav_heading_sidebar svg {
    margin-right: 0.5rem;
}
.nav::before {
    content: "";
    position: absolute;
    border-top: 1px solid var(--dark);
    width: calc(20.5rem - 17rem);
    top: 0;
    left: 0;
    margin-left: 1.5rem;
}
.with-115 {
    width: 3.5rem;
}
.user .nav::before {
    width: 85%;
}
.expanded .nav::before {
    width: 20.5rem;
    content: "";
    border-top: 1px solid var(--dark);
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 1.5rem;
    width: 90%;
}
.input-group-container .nav:before {
    width: 90%;
}
.nav_heading_sidebar::after {
    content: "";
    position: absolute;
    border-bottom: 1px solid var(--dark);
    background-color: var(--dark);
    width: 100%;
    top: 0;
    left: 0;
}

.icon_heading svg {
    opacity: 0.3;
}
.nav svg {
    font-size: 2.2rem;
}
.nav h3,
.nav h5 {
    margin-left: 0.6rem;
}
.nav:hover svg {
    color: var(--text_color);
}
.nav:hover h3 {
    color: var(--text_color);
}

.count {
    opacity: 0.5;
}

.game_clock {
    padding: 1rem;
    text-align: center;
    font-family: "Roboto Mono", monospace;
    margin-top: 0.5rem;
    position: absolute;
    bottom: 1rem;
    margin-left: 1rem;
}

.time_clock h2 {
    font-size: 3.6rem;
}
.game_clock h2 {
    font-size: 3rem;
    font-weight: bold;
}
.game_clock h5 {
    font-weight: bold;
}

.overflow_text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}

.tooltip_no span {
    display: none;
    z-index: 2;
    background-color: var(--dark);
    border-radius: 0.5rem;
    padding: 1em;
    position: fixed;
}
.tooltip_no:hover span {
    display: block;
    position: fixed;
    overflow: hidden;
}
