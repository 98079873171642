.icon {
  display: flex;
  align-items: center;
}
.icon svg {
  font-size: 3rem;
  color: var(--text_color);
}
.icon_4 svg {
  font-size: 3rem;
  cursor: pointer;
}
.close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--text_color);
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.close svg {
  font-size: 2.3rem;
}

.icon__primary {
  width: 3rem;
  height: 3rem;
  background: var(--light);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  margin-left: 0;
  cursor: pointer;
}

.icon__primary:hover {
  background: var(--accent);
}
.icon__primary svg {
  font-size: 2rem;
}
.disable svg {
  color: var(--text_color_5);
  cursor: context-menu;
}
.disable .icon__primary {
  color: var(--text_color_5);
  cursor: context-menu;
  background: none;
}
.disable .tab-btn {
  color: var(--text_color_5);
  cursor: context-menu;
}

.active {
  background: transparent;
  box-shadow: none;
}

.pagination svg {
  font-size: 3.5rem;
  fill: var(--text_color);
}
.icon-search {
  width: 4rem;
  height: 4rem;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  align-items: center;
  color: var(--text_color);
  position: absolute;
}
.icon-search svg {
  font-size: 2.5rem;
  margin-left: 1rem;
}

.toggle .input-icon {
  height: 3rem;
}

/* Custom */

.mv-1 {
  margin: 1rem 0;
}
.m-0 {
  margin: 0;
}
.mt--0_5 {
  margin-top: -1px;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-4 {
  margin-top: 4.25rem;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.flex-column {
  flex-direction: column;
}
.d-flex {
  display: flex;
}
.center {
  align-items: center;
  display: flex;
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-end {
  align-items: flex-end;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-space-around {
  justify-content: space-around;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
