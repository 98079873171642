.auth__container {
  display: flex;
  justify-content: center;
}
.auth {
  width: 42.5rem;
  display: flex;
  justify-content: center;
  background: var(--medium);
  box-shadow: 0px 0px 50px 5px rgba(0, 0, 0, 0.25);
  border-radius: 2rem;
}
.auth__inputs {
  width: 100%;
  padding: 3rem;

  color: var(--text_color);
}
.login_logo {
  height: 25vh;
  margin-bottom: 20px;
}

.pentest-auth-container label h6 {
  font-size: 14px;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.pentest-auth-container .input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: rgb(255 255 255 / 14%);
  background-clip: padding-box;
  border: 1px solid rgb(255 255 255 / 35%);
  border-radius: 0.25rem;
  height: 40px;
}
.pentest-auth-container input[placeholder="password"] {
  border-radius: 0.25rem 0 0 0.25em;
}
.pentest-auth-container input[placeholder="password"] + .input-icon {
  border-radius: 0 0.25rem 0.25em 0;
  border-left-color: transparent;
}
.pentest-auth-container input[placeholder="confirm password"] {
  border-radius: 0.25rem 0 0 0.25em;
}
.pentest-auth-container input[placeholder="confirm password"] + .input-icon {
  border-radius: 0 0.25rem 0.25em 0;
  border-left-color: transparent;
}
.pentest-auth-container .input-icon {
  height: 40px;
  border-radius: 0.25rem;
  background-color: rgb(255 255 255 / 14%);
  border: 1px solid rgb(255 255 255 / 35%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pentest-auth-container .btn {
  border: 1px solid rgb(255 255 255 / 35%);
  width: 100%;
  height: 40px;
  border-radius: 0.25rem;
}
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid var(--light);
}

.login-separater {
  text-align: center;
  position: relative;
}

.login-separater span {
  position: relative;
  top: 26px;
  margin-top: -10px;
  background: var(--dark);
  padding: 5px;
  font-size: 12px;
  color: var(--text_color);
  border-radius: 3px;
}
