.create-join_team {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 4rem;
}

.card__container {
    background: var(--medium);
    box-shadow: 0px 0px 50px 5px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
    overflow: hidden;
}

.challenge__img {
    width: 8rem;
    height: 8rem;
    border-radius: 6rem;
}
.card__primary {
    padding: 3rem;
    color: var(--text_color);
    position: relative;
}
.card__secondary {
    padding: 0 1rem;
    background-color: var(--medium);
}

.card_img {
    flex: 0.15;
}
.card__heading {
    font-weight: bold;
    font-size: 2.5rem;
    overflow: hidden;
    width: 90%;
}

.card_p {
    word-wrap: break-word;
    word-break: break-all;
    font-style: normal;
    font-size: 1.4rem;
    display: flex;
    position: relative;
    align-items: center;
}
.card_p_lg {
    font-size: 1.7rem;
}
