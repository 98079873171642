.m_btn {
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
    border: 0;
    outline: 0;
    cursor: pointer;
    font-style: normal;
    font-size: 1.4rem;
    color: var(--text_color);
    padding: 1em 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.m_btn--glow {
    font-size: 2.4rem;
    background: transparent;
    color: #fff;
    padding: 0.4em 2.5em;
    border: 0;
    outline: 0;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5),
        0px 0px 7px rgba(255, 255, 255, 0.3);
    border-radius: 5rem;
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-family: "Roboto Mono", monospace;
    font-weight: bold;
}

.m_btn--medium {
    background: var(--light);
    padding: 0.5em 2em;
}
.m_btn--secondary {
    background: var(--red);
    padding: 0.5em 2em;
}
.m_btn--medium {
    background-position: center;
    transition: background 1s;
}
.m_btn--medium:hover {
    background: var(--accent)
        radial-gradient(circle, transparent 1%, var(--accent) 1%) center/15000%;
}
.m_btn--medium:active {
    background-color: rgba(0, 0, 0, 0.1);
    background-size: 100%;
    transition: background 0s;
}
.m_not_found_btn {
    background: #606060;
    padding: 0.5em 2em;
}
.m_not_found_btn:hover {
    background-color: #be1622;
}
.m_tab-btn {
    background: var(--light);
    padding: 0.5em 2em;
    opacity: 0.5;
    cursor: context-menu !important;
}
.m_tab-btn:hover {
    background-color: var(--light) !important;
}
.m_tabs-btn {
    background: var(--light);
    padding: 0.5em 2em;
    width: 12rem;
}

.m_text-center {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

a {
    text-decoration: none !important;
    color: inherit;
}
.m_btn--stretch {
    width: 14.2rem;
}

.m_btn--lg {
    width: 28rem;
    height: 5rem;
    border-radius: 1.6rem;
}

@media screen and (max-width: 768px) {
    .m_btn--glow {
        font-size: 1.8rem;
    }
}
