h1,
h2,
h3,
h4,
h5,
h6 {
    font-style: normal;
    font-weight: normal;
    color: var(--text_color);
}
h1 {
    font-size: 3.6rem;
}
h2 {
    font-size: 2.2rem;
}
h3 {
    font-size: 1.8rem;
}
h4 {
    font-size: 1.6rem;
}
h5 {
    font-size: 1.4rem;
}
h6 {
    font-size: 1rem;
}

.spaced {
    color: var(--text_color_5);
    letter-spacing: 0.2em;
    line-height: 1.2rem;
}
