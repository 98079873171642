.plan_challenges {
  max-height: 60vh;
  min-height: 100px;
  padding: 0 10px;
  overflow: auto;
}
.plan_challenges_item {
  padding: 5px 20px;
  background: var(--dark);
  margin: 10px 0;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.plansPanel .toggle__card {
  width: 60vw;
}
.plan_image {
  position: relative;
}
.plan_image .icon {
  visibility: hidden;
  position: absolute;
  top: 2;
  right: 2;
}
.plan_image:hover .icon {
  visibility: visible;
}
/* .planChallenges .toggle__card {
  width: 40vw;
} */
